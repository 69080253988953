.facebook-button {
  background-color: #1b74e4;
  color: white;
  border: none;
  border-radius: 3px;
  font-weight: bold;
  display: flex;
  align-items: center;
  min-width: 100%;
}

.facebook-button:hover {
  background-color: #166fe5;
  cursor: pointer;
}

.google-button {
  min-width: 100%;
}

.centered-card {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

body {
  height: 100%
}
